<template>
  <div class="withdrawal-history_wrapper">
    <div v-if="tableStore.isEmpty" class="withdrawal-history_fallback">
      <SvgoProfileWithdrawalSandclock />
      <h1>{{ $t('profile.withdrawalHistory.fallback.title') }}</h1>
      <p>{{ $t('profile.withdrawalHistory.fallback.subtitle') }}</p>
      <SharedButton>{{ $t('topupBalance') }}</SharedButton>
    </div>
    <template v-else>
      <div v-if="isMobile" role="button" class="withdrawal-history_go-back" @click="handleGoBack">
        <SvgoArrowLeftIcon :width="24" :height="24" />
        <span>{{ $t('profile.withdrawalHistory.goBack') }}</span>
      </div>
      <ProfileWithdrawalHistoryTableSkeleton
        :is-loaded="isTableLoaded"
        :cols="tableStore.cols"
        :rows="tableStore.rows"
      />
      <div v-show="isTableLoaded" class="withdrawal-history_footer">
        <SharedKitButton
          :is-loading="!tableStore.isLoaded"
          :is-disabled="!tableStore.isLoaded"
          :color-preset="BattleColorPresets.TRANSPARENT_WHITE"
          :size-preset="SizePresets.M"
          :type-preset="TypePresets.RECTANGLE"
          @click="loadMoreHandler"
        >
          <SharedText :weight="FontWeights.BOLD">
            {{ $t('profile.withdrawalHistory.loadMore') }}
          </SharedText>
        </SharedKitButton>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { BattleColorPresets } from '~/features/battles/presets/SharedKitButton.presets';
import { SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { useWithdrawalHistory } from '~/features/profile/store/withdrawalHistory/WithdrawalHistory.store';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';
import { ProfileTabsEvents } from '~/repository/amplitude/events/profile/profile-tabs';
import { FontWeights } from '~/types/SharedFont.types';

const tableStore = useWithdrawalHistory();

onMounted(async () => {
  if (tableStore.isLoaded) return;
  await tableStore.fetchTable();
});

const changeMD = GlobalUtils.Media.changeByMedia('md');

const isMobile = computed(() => {
  return changeMD(false, true);
});

const profilePresentationStore = useProfilePresentationStore();

const isTableLoaded = computed(() => tableStore.isLoaded || nextPageIndex.value > 2);

const nextPageIndex = ref(2);

function loadMoreHandler() {
  ProfileTabsEvents.loadMoreClicked({
    'Tab Name': profilePresentationStore.currentTab,
  });
  tableStore.fetchTable(nextPageIndex.value);
  nextPageIndex.value++;
}

function handleGoBack() {
  profilePresentationStore.activatePreviousTab();
}
</script>

<style scoped src="./ProfileWithdrawalsTabController.scss" lang="scss" />
