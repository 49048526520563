<script setup lang="ts">
// eslint-disable-next-line vue/prefer-import-from-vue
import { toNumber } from '@vue/shared';
import {
  InventoryExchangePopupHeadButtonColorPreset,
  InventoryExchangePopupHeadButtonTextPreset,
  InventoryExchangePopupHeadButtonFormPreset,
} from '~/features/profile/components/InventoryExchangePopupHead/InventoryExchangePopupHead.data';
import { useInventoryStore } from '~/store/inventory/inventory';
import { ProfileInventoryEvents } from '~/repository/amplitude/events/profile/profile-inventory';

const changeMD = GlobalUtils.Media.changeByMedia('md');

const inventoryStore = useInventoryStore();
const { currentTradeItem, currentInventoryExchangePopupTime, isExchangePopupVisible } = storeToRefs(inventoryStore);

const { timerData, start } = useTimer(currentInventoryExchangePopupTime, true);

const timerText = computed(() => {
  const { minutes, seconds, rawTime } = timerData.value;
  start();
  if (rawTime === 0) {
    isExchangePopupVisible.value = false;
  }

  return `${minutes}:${seconds}`;
});

const initialPrice = computed<number>(() => toNumber(currentTradeItem.value?.price));
const specialPrice = computed<number>(() => toNumber(initialPrice.value) * 1.05);
const viewedPrice = computed<string>(() => {
  const price = timerData.value.rawTime > 0 ? specialPrice.value : initialPrice.value;
  return price.toFixed(2);
});

const handleSellItem = () => {
  ProfileInventoryEvents.changeSellItemClicked({
    'Item Name': currentTradeItem.value!.name,
    'Item Price': Number(currentTradeItem.value!.price),
    'Item Rarity': currentTradeItem.value!.rare,
    'Item Type': currentTradeItem.value!.type,
  });
  currentTradeItem.value!.price = viewedPrice.value;
  inventoryStore.sellItem(currentTradeItem.value!.id);
  inventoryStore.toggleExchangePopup();
};

const isRulesSelectVisible = computed<boolean>(() => {
  return changeMD(false, true);
});

const isRulesVisible = ref(false);
const toggleRulesVisibility = () => {
  isRulesVisible.value = !isRulesVisible.value;
};
</script>

<template>
  <div v-if="currentTradeItem" class="inventory-exchange-popup-head">
    <div class="inventory-exchange-popup-head__title">{{ $t('profile.inventoryExchange.title') }}</div>
    <div class="inventory-exchange-popup-head__content">
      <SharedKitDropItem
        :name="currentTradeItem.name"
        :image="currentTradeItem.img"
        :color="GlobalUtils.Colors.getColorsRarity(currentTradeItem.rare)"
        :price="String(currentTradeItem.price)"
        no-overlay
      />
      <div class="inventory-exchange-popup-head__content-info">
        <div class="inventory-exchange-popup-head__content-text">{{ $t('profile.inventoryExchange.subtitle') }}</div>
        <SharedButtonNew
          v-if="timerData.rawTime > 0"
          :form="InventoryExchangePopupHeadButtonFormPreset"
          :color="InventoryExchangePopupHeadButtonColorPreset"
          :text-props="InventoryExchangePopupHeadButtonTextPreset"
          class="inventory-exchange-popup-head__content-btn"
          @click="handleSellItem"
        >
          <template #prepend>
            <span class="inventory-exchange-popup-head__content-btn-prepend">
              {{ $t('profile.inventoryActions.sell') }}
            </span>
          </template>
          <div class="inventory-exchange-popup-head__content-btn-price">
            {{ viewedPrice }}
            <UserController>
              <template #default="{ isFake }">
                <SharedPrettyCurrency :currency="currentTradeItem.currency" :is-fake="isFake" />
              </template>
            </UserController>
          </div>
          <template #append>
            <div class="inventory-exchange-popup-head__content-btn-append">
              {{ timerText }}
            </div>
          </template>
        </SharedButtonNew>
      </div>
    </div>
    <div v-if="isRulesSelectVisible" class="inventory-exchange-popup-head__rules">
      <div class="inventory-exchange-popup-head__rules-main">
        <div class="inventory-exchange-popup-head__rules-left">
          <SvgoInfoIcon :width="20" :height="20" />
          <div>{{ $t('profile.inventoryExchange.rulesTitle') }}</div>
        </div>
        <SvgoArrowRightIcon
          class="inventory-exchange-popup-head__rules-right"
          :class="{ 'inventory-exchange-popup-head__rules-right--active': isRulesVisible }"
          :width="20"
          :height="20"
          @click="toggleRulesVisibility"
        />
      </div>
      <transition name="fade" mode="out-in">
        <ol v-show="isRulesVisible" class="inventory-exchange-popup-head__rules-list">
          <li
            v-for="(_, i) in $tm('profile.inventoryExchange.contentRules')"
            :key="i"
            class="inventory-exchange-popup-head__rules-item"
          >
            {{ $t(`profile.inventoryExchange.contentRules.${i}`) }}
          </li>
        </ol>
      </transition>
    </div>
  </div>
</template>

<style scoped lang="scss" src="./InventoryExchangePopupHead.scss" />
