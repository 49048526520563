<script setup lang="ts">
import type { CSSProperties } from 'vue';
import type { ICasesItem } from '~/features/mainPage/Types/cases/client.types';
import type { ISharedKitCaseDefaultColors } from '~/components/SharedKitCase/SharedKitCase.types';
import { useEventStore } from '~/store/event/event.store';
import { useCaseStore } from '~/features/cases/store/useCases.store';
import mock1 from '~/assets/images/Case/Event/mock1.png';
import mock2 from '~/assets/images/Case/Event/mock2.png';
import mock3 from '~/assets/images/Case/Event/mock3.png';
import mock4 from '~/assets/images/Case/Event/mock4.png';
import { ProfileInventoryEvents } from '~/repository/amplitude/events/profile/profile-inventory';

const eventStore = useEventStore();
const caseStore = useCaseStore();
const { eventCases } = storeToRefs(caseStore);

const mockColorOptions = ref<ISharedKitCaseDefaultColors>({
  background: 'var(--event-case-gradient)',
  circle: 'radial-gradient(50% 50% at 50% 50%, #35EC80 87.5%, #15B839 100%)',
  hover: 'linear-gradient(180deg,#F2BB2B, #A90BA3)',
  shadow: '#B9FF23',
});

const sliderStyles: CSSProperties = {
  gap: '24px',
};

const mockData = ref<ICasesItem[]>([
  {
    coinsData: [10, 40],
    counterData: null,
    disabled: false,
    id: 5850,
    image: mock1,
    labelData: {
      background: '#00b047',
      color: '#ffffff',
      text: 'Бестселлер',
    },
    link: 'spring24_warm-inv',
    name: 'Колпак на ДР',
    priceData: {
      currency: 'RUB',
      old: 49,
      price: 15,
    },
  },
  {
    coinsData: [10, 40],
    counterData: null,
    disabled: false,
    id: 5851,
    image: mock2,
    labelData: {
      background: '#FF3D00',
      color: '#ffffff',
      text: 'Новинка',
    },
    link: 'spring24_tpab',
    name: 'Праздничная пиньята',
    priceData: {
      currency: 'RUB',
      old: 99,
      price: 29,
    },
  },
  {
    coinsData: [10, 40],
    counterData: null,
    disabled: false,
    id: 5852,
    image: mock3,
    labelData: {
      background: '#FF3D00',
      color: '#ffffff',
      text: 'Multicast x1-x4',
    },
    link: 'spring24_fire',
    name: 'Birthday Multicast',
    priceData: {
      currency: 'RUB',
      old: 299,
      price: 99,
    },
  },
  {
    coinsData: [10, 40],
    counterData: null,
    disabled: false,
    id: 5853,
    image: mock4,
    labelData: {
      background: '#FF3D00',
      color: '#ffffff',
      text: 'Новинка',
    },
    link: 'spring24_fire',
    name: 'VIP Party Pass',
    priceData: {
      currency: 'RUB',
      old: 999,
      price: 2499,
    },
  },
  {
    coinsData: [10, 40],
    counterData: null,
    disabled: false,
    id: 5853,
    image: mock4,
    labelData: {
      background: '#FF3D00',
      color: '#ffffff',
      text: 'Новинка',
    },
    link: 'spring24_fire',
    name: 'VIP Party Pass',
    priceData: {
      currency: 'RUB',
      old: 999,
      price: 2499,
    },
  },
  {
    coinsData: [10, 40],
    counterData: null,
    disabled: false,
    id: 5853,
    image: mock4,
    labelData: {
      background: '#FF3D00',
      color: '#ffffff',
      text: 'Новинка',
    },
    link: 'spring24_fire',
    name: 'VIP Party Pass',
    priceData: {
      currency: 'RUB',
      old: 999,
      price: 2499,
    },
  },
]);

const casesToShow = computed(() => {
  return eventCases.value.length ? eventCases.value : mockData.value;
});

const handleCasePromotionClicked = (slide: ICasesItem) => {
  ProfileInventoryEvents.casePromotionClicked({
    'Case Name': slide.name,
    'Case Price': slide.priceData.price!,
  });
};
</script>

<template>
  <div class="case-inventory-empty-widget">
    <div class="case-inventory-empty-widget__top">
      <div class="case-inventory-empty-widget__title">посмотри эти кейсы</div>
      <div class="case-inventory-empty-widget__subtitle">Новых предметов пока нет, но это исправимо</div>
    </div>

    <SharedSlider
      :show-buttons="true"
      :slides="casesToShow"
      slide-key="name"
      :slider-styles="sliderStyles"
      :slides-amount="casesToShow.length >= 4 ? 4 : casesToShow.length"
      :slides-mobile-amount="casesToShow.length >= 4 ? 4 : casesToShow.length"
    >
      <template #slide="{ slide }">
        <SharedKitCase
          class="case-inventory-empty-widget__case"
          is-small-size
          :event-data="{
            color: eventStore.coinColor,
            icon: eventStore.coinIcon,
          }"
          :case-data="slide as unknown as ICasesItem"
          :variant="{
            colorData: eventCases.length ? ({} as ISharedKitCaseDefaultColors) : mockColorOptions,
            locked: !!slide.locked,
            type: 'default',
            showRag: false,
          }"
          @click="handleCasePromotionClicked(slide as unknown as ICasesItem)"
        />
      </template>
    </SharedSlider>
  </div>
</template>

<style scoped lang="scss" src="./CaseInventoryEmptyWidget.scss" />
