<template>
  <ProfileStatBase :header="header">
    <template #badges>
      <ProfileStatIconBadge v-for="badge in badges" :key="badge.id" :title="badge.title" :icon="badge.icon">
        {{ badge.value }}
      </ProfileStatIconBadge>
    </template>
  </ProfileStatBase>
</template>

<script setup lang="ts">
import type { IStatBadge } from '../StatIconBadge/StatIconBadge.types';
import { EStatIcons } from '../StatIconBadge/StatIconBadge.types';
import type { IStatUpgradesProps } from './StatUpgrades.types';

const { t } = useI18n();

const header = t('profile.statWidget.headers.upgrades');

const props = defineProps<IStatUpgradesProps>();

const badges: IStatBadge[] = [
  {
    icon: EStatIcons.UPGRADE,
    id: 0,
    title: t('profile.statWidget.badges.upgrades.allCount'),
    value: props.allCount,
  },
  {
    icon: EStatIcons.STAR,
    id: 1,
    title: t('profile.statWidget.badges.upgrades.winCount'),
    value: props.winCount,
  },
  {
    icon: EStatIcons.WINRATE,
    id: 2,
    title: t('profile.statWidget.badges.upgrades.winRate'),
    value: props.winRate,
  },
  {
    icon: EStatIcons.MAX,
    id: 3,
    title: t('profile.statWidget.badges.upgrades.maxDrop'),
    value: props.maxDrop,
  },
];
</script>
