<template>
  <ProfileStatBase :header="header">
    <template #badges>
      <ProfileStatIconBadge v-for="badge in badges" :key="badge.id" :title="badge.title" :icon="badge.icon">
        {{ badge.value }}
      </ProfileStatIconBadge>
      <ProfileStatIconBadge
        v-if="!currentUser?.isBot"
        :icon="EStatIcons.SCORE"
        :title="$t('profile.statWidget.badges.battles.lastFiveWinner')"
      >
        <template #no-gradient>
          <div class="user-statistics-winLooseStrike">
            <span v-for="(result, i) in lastFiveWinner" :key="i" :class="winLooseClassesMap[result]">{{ result }}</span>
          </div>
        </template>
      </ProfileStatIconBadge>
    </template>
    <template v-if="!!$slots.footer" #footer>
      <slot name="footer" />
    </template>
  </ProfileStatBase>
</template>

<script setup lang="ts">
import type { IStatBattlesProps } from './StatBattles.types';
import type { IStatBadge } from '~/features/profile/components/StatIconBadge/StatIconBadge.types';
import { EStatIcons } from '~/features/profile/components/StatIconBadge/StatIconBadge.types';
import { PaymentColors } from '~/features/payment/constants/colors.constants';
import { useUserStore } from '~/store/user/user.store';

const props = defineProps<IStatBattlesProps>();

const { t } = useI18n();

const header = t('profile.statWidget.headers.battles');

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);

const badges = computed<IStatBadge[]>(() => {
  const baseBadges: IStatBadge[] = [
    {
      icon: EStatIcons.KNIVES,
      id: 0,
      title: t('profile.statWidget.badges.battles.allCount'),
      value: props.allCount,
    },
    {
      icon: EStatIcons.STAR,
      id: 1,
      title: t('profile.statWidget.badges.battles.winCount'),
      value: props.winCount,
    },
    {
      icon: EStatIcons.WINRATE,
      id: 2,
      title: t('profile.statWidget.badges.battles.winRate'),
      value: props.winRate,
    },
    {
      icon: EStatIcons.MAX,
      id: 3,
      title: t('profile.statWidget.badges.battles.maxDrop'),
      value: props.maxDrop,
    },
  ];

  return !currentUser.value?.isBot ? baseBadges : baseBadges.slice(0, -1);
});

const winLooseClassesMap: Record<string, string> = {
  L: 'user-statistics-winLooseStrike_loose',
  W: 'user-statistics-winLooseStrike_win',
};

const winColor = PaymentColors.SUCCESS;
const looseColor = PaymentColors.FAIL;
</script>

<style scoped>
.user-statistics-winLooseStrike {
  --win-color: v-bind(winColor);
  --loose-color: v-bind(looseColor);
}
</style>

<style scoped src="./StatBattles.scss" lang="scss"></style>
