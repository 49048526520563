export enum EStatIcons {
  KNIVES = 'knives',
  MAX = 'max',
  OPEN_CASE = 'open_case',
  SCORE = 'score',
  STAR = 'star',
  STEAM = 'steam',
  UPGRADE = 'upgrade',
  WINRATE = 'winrate',
}

export interface IStatBadge {
  icon: EStatIcons;
  id: number;
  title: string;
  value?: string;
}

export interface IUserStatisticsBadgeProps {
  icon: EStatIcons;
  title: string;
}
