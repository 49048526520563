<template>
  <SkeletonComponentLoader>
    <section v-if="!currentUser?.userId" class="tabs-skeleton">
      <SkeletonBaseForeground class="tabs-skeleton__foreground" />
    </section>
    <div v-else-if="!currentUser?.isBot" v-show="profilePresentationStore.isTabBarVisible" class="tabs-wrapper">
      <template v-for="tab in tabsMap" :key="tab.id">
        <TabComponent
          :name="$t(tab.localeKey)"
          :icon="tab.icon"
          :count="tab.id === EProfileTabName.INVENTORY ? inventoryCount : ''"
          :is-selected="profilePresentationStore.isTabActive(tab.id)"
          :class="`tabs-wrapper__${tab.id.toLowerCase()}`"
          @click="activateTabHandler(tab.id)"
        />
      </template>
    </div>
  </SkeletonComponentLoader>

  <component
    :is="profilePresentationStore.resolveTabComponent(profilePresentationStore.currentTab)"
    class="active-component"
  />
</template>

<script setup lang="ts">
import TabComponent from '../../components/TabComponent/TabComponent.vue';
import { useStatGeneralStore } from '../../store/statGeneral/StatGeneral.store';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';
import { tabsMap } from '~/features/profile/store/profilePresentation/ProfilePresentation.data';
import { useUserStore } from '~/store/user/user.store';
import { ProfileTabsEvents } from '~/repository/amplitude/events/profile/profile-tabs';
import { EProfileTabName } from '~/repository/amplitude/types/profile/profile-tabs';

const profilePresentationStore = useProfilePresentationStore();

const statStore = useStatGeneralStore();

const inventoryCount = computed(() => {
  return statStore.data.inventoryCount;
});

const userStore = useUserStore();
const { currentUser } = storeToRefs(userStore);

const activateTabHandler = (tabId: EProfileTabName) => {
  ProfileTabsEvents.tabClicked({
    'Tab Name': tabId,
  });

  profilePresentationStore.activateTab(tabId);
};
</script>

<style src="./TabController.style.scss" lang="scss" scoped></style>
