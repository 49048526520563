<template>
  <div class="user-action-wrapper" :class="{ 'user-action-wrapper--other-user': !isMe }">
    <div class="user-action-wrapper__name">{{ userName }}</div>
    <div v-if="isMe" class="user-action-wrapper__balance">
      <div class="user-action-wrapper__balance-money">
        <HeaderBalance :amount="userFinance" :variant="{ type: EHeaderBalanceTypes.MONEY, showIcon: true }" />
      </div>
      <div class="user-action-wrapper__balance-coins">
        <HeaderBalance
          :amount="userCoin"
          :variant="{ icon: eventStore.coinIcon, color: eventStore.coinColor, type: EHeaderBalanceTypes.COINS }"
        />
        <SharedKitTooltip position="top" @click="handleOpen">
          <template #default>
            <div class="user-action-wrapper__balance-coins-text">
              {{ $t('profile.userActionTooltip') }}
            </div>
          </template>
        </SharedKitTooltip>
      </div>
    </div>
    <SharedKitButton
      v-if="isMe"
      class="user-action-wrapper__button"
      :color-preset="ColorPresets.DOTA.ACCENT"
      :type-preset="TypePresets.RECTANGLE"
      :size-preset="SizePresets.M"
      :text="t('topupBalance')"
      :link-props="{
        to: useLinks().BALANCE_POPUP,
      }"
    />
    <div v-if="!isMe" class="user-action-wrapper__battle-stat">
      <div class="user-action-wrapper__battle-stat-numbers">{{ winrate }}</div>
      <SvgoDoubleSwordBattle class="user-action-wrapper__battle-stat-icon" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IUserActionWrapperProps } from './UserActionWrapper.types';
import { ColorPresets, SizePresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { EHeaderBalanceTypes } from '~/components/HeaderBalance/HeaderBalance.types';
import { useEventStore } from '~/store/event/event.store';
import { useUserStore } from '~/store/user/user.store';

const route = useRoute();
const eventStore = useEventStore();
const userStore = useUserStore();

const { t } = useI18n();

const props = defineProps<IUserActionWrapperProps>();

const isOpenPopup = ref<boolean>(false);

const userFinance = computed(() => {
  return Number(userStore.user?.finance.balance);
});

const handleOpen = () => {
  isOpenPopup.value = !isOpenPopup.value;
};

const userCoin = computed(() => {
  return Number(userStore.user?.finance.coinBalance);
});

const winrate = ref<string>('0:0');
onMounted(async () => {
  if (props.isMe) return;
  const { win, lose } = await userStore.getWinrate(Number(route.params.userId));
  winrate.value = `${win}:${lose}`;
});
</script>

<style src="./UserActionWrapper.style.scss" scoped lang="scss" />
