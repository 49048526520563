import type { CSSProperties } from 'vue';

export interface IUserAvatarContainerProps {
  status: EUserAvatarContainerStatus;
  avatarSrc: string;
  userLevel: number;
  currentExperience?: number;
  experienceToNextLevel?: number;
  wasGeneratedLevel?: boolean;
  isBot?: boolean;
  isCurrentUser: boolean;
}

export interface IUserAvatarContainerEmits {
  (event: 'open-exp-popup'): void;
  (event: 'generate-level'): void;
}

export interface IUserAvatarCircle {
  class: string;
  show: boolean;
  style?: CSSProperties;
}

export enum EUserAvatarContainerStatus {
  DEFAULT,
  READY_TO_GENERATE,
  GENERATING,
  DONE_GENERATING,
}
