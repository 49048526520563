<template>
  <div :class="tabClasses">
    <component :is="icon" class="tab-component__icon" />
    <p class="tab-component__name">{{ name }}</p>
    <div v-if="count" class="tab-component__count">{{ count }}</div>
  </div>
</template>

<script setup lang="ts">
import type { ITabComponentProps } from './TabComponent.types';

const props = defineProps<ITabComponentProps>();
const tabClasses = computed(() => ['tab-component', { 'tab-component--selected': props.isSelected }]);
</script>

<style src="./TabComponent.style.scss" scoped lang="scss"></style>
