import type { IStatUpgradesState } from '~/features/profile/store/statUpgrades/StatUpgrades.types';
import { Currency } from '~/constants/currency.constants';
import type { IBaseStatRequestParams } from '~/repository/modules/profile/types/statistics';
import { useUserStore } from '~/store/user/user.store';

const { calculatePercents } = GlobalUtils.Maths;

export const useStatUpgradesStore = defineStore('profile/statUpgrades', () => {
  const { $api } = useNuxtApp();
  const userStore = useUserStore();
  const route = useRoute();

  const isLoaded = ref(false);
  const state = ref({} as IStatUpgradesState);

  function fetch() {
    const user = userStore.user;
    if (!user) return;
    const { isFake, finance } = user;
    const currentCurrency = isFake ? Currency.DIAMOND : Currency[finance.currency];
    return GlobalUtils.Api.handleRequest(async () => {
      const params: IBaseStatRequestParams = {
        userId: Number(route.params.userId),
      };

      const resp = await $api.profile.getStatUpgrades(params);

      if (resp.status) {
        const checkIfNotNan = resp.data.winCount && resp.data.allCount && resp.data.allCount > 0;
        const winRate = checkIfNotNan
          ? calculatePercents(resp.data.winCount, resp.data.allCount).toFixed(2) + '%'
          : '-';

        state.value = {
          allCount: formatNumber(resp.data.allCount, { dashIfZero: true }),
          // TODO SharedPrettyCurrency!!
          maxDrop: resp.data.maxDrop
            ? formatNumber(resp.data.maxDrop, { dashIfZero: true }) + ' ' + currentCurrency
            : '-',
          winCount: formatNumber(resp.data.winCount, { dashIfZero: true }),
          winRate,
        };

        isLoaded.value = true;
      }
    });
  }

  return { fetch, isLoaded, state };
});
