<template>
  <div
    class="battles-table-row"
    :class="{ ['battles-table-row--active']: isBattleActive, ['battles-table-row--best']: battle.isBest }"
  >
    <div v-if="battle.isBest" class="battles-table-row__best-battle-label-wrapper">
      <span class="battles-table-row__best-battle-label-text">{{ $t('profile.battles.bestBattleLabelText') }}</span>
    </div>
    <div class="battles-table-row__content">
      <div class="content__cases">
        <div class="battles-table-row__round">
          <BattlesRoundCircle :active="isBattleActive" :done="isBattleDone" :text="battle.cases.length.toString()" />
        </div>
        <SharedDragAndSlideContainer :bounce-multiplier="0.9" bounced="always" class="content__cases_container">
          <NuxtLink
            v-for="caseItem in battle.casesCompact"
            :key="caseItem.caseId"
            :target="LinkTarget.LINK_BLANK"
            :to="useLinks().OPEN_CASE + caseItem.data.name"
          >
            <BattlesMiniCase :image="caseItem.data.image" :multiplier="caseItem.amount">
              <div class="content__cases_container__case-additional">
                <SharedMiniLabel
                  v-if="caseItem.data.multifix"
                  :bg-color="BattlesGradientConfigs.MULTIFIX"
                  :text-props="{ size: changeXl(FontSizes.MEDIUM, FontSizes.SMALL_2XS), weight: FontWeights.BOLD }"
                  class="case-additional__multi"
                >
                  FIX x{{ caseItem.data.multifix }}
                </SharedMiniLabel>
                <SharedMiniLabel
                  v-if="checkWhetherDataRangeValid(caseItem.data.coins)"
                  :height="changeXl(22, 14)"
                  :text-props="{
                    size: changeXl(FontSizes.MEDIUM, FontSizes.SMALL_2XS),
                    weight: FontWeights.BLACK,
                    color: '#F03573',
                    wrap: 'nowrap',
                  }"
                  class="case-additional__coins"
                >
                  <template #prepend>
                    <NuxtImg
                      :src="`${useLinks().domen}/img/event/default/default-coin_icon.svg`"
                      class="global__tasty-coin_icon"
                    />
                  </template>
                  <span class="container-case__additional-text">
                    {{ formatDataRange(caseItem.data.coins) }}
                  </span>
                </SharedMiniLabel>
              </div>
            </BattlesMiniCase>
          </NuxtLink>
        </SharedDragAndSlideContainer>
      </div>
      <div class="content__actions">
        <div class="content__actions_info">
          <SharedText v-if="battle.price" :weight="FontWeights.BLACK" class="info__price" font-case="upper">
            <UserController>
              <template #default="{ user }">
                {{ useFormatCurrency(battle.price, user?.finance?.currency) }}
              </template>
            </UserController>
          </SharedText>
          <div class="info__players">
            <BattlesCurrentPlayers
              is-clickable
              :battle-status="battle.status as unknown as EBattleStatuses"
              :slots="battleMembers as unknown as TSlotsWithMembers"
            />
            <SharedText
              v-if="isBattleActive"
              :color="battle.status === EProfileBattleStatus.CREATED ? Colors.NEUTRAL.WHITE : Colors.NEUTRAL.WHITE_50"
              :size="changeXl(FontSizes.LARGE, FontSizes.SMALL)"
              :weight="FontWeights.BOLD"
              align="center"
              as="span"
            >
              {{ `${battle.members?.length} / ${battle.slots}` }}
            </SharedText>
            <div v-else-if="isBattleDone" class="content__actions_totalSell">
              <span v-if="battle.totalSell" class="content__actions_totalSell_price">
                <UserController>
                  <template #default="{ user }">
                    {{ useFormatCurrency(battle.totalSell, user?.finance?.currency) }}
                  </template>
                </UserController>
              </span>
              <span class="content__actions_totalSell_nickname">{{ winnerName }}</span>
            </div>
          </div>
        </div>
        <div class="content__actions_buttons">
          <BattlesActionButtonsGroup
            :main-button-title="primaryButtonText"
            :secondary-button-title="secondaryButtonText"
            :is-secondary-disabled="
              battle.status === EProfileBattleStatus.ROLLED ||
              battle.status === EProfileBattleStatus.STARTED ||
              battle.status === EProfileBattleStatus.FINALIZED ||
              isCancelDisabled
            "
            @on-main-click="handlePrimaryButtonClick"
            @on-secondary-click="handleSecondaryButtonClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IBattlesItemProps, IBattlesItemEmits } from './BattlesItem.types';
import type { TSlotsWithMembers } from '~/features/battles/types/battlesStore.types';
import type { EBattleStatuses } from '~/repository/modules/battles';
import { LinkTarget } from '~/constants/attributes';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import { BattlesGradientConfigs } from '~/features/battles/constants/styles';
import BattlesUtils from '~/features/battles/utils';
import { Colors } from '~/constants/colors.constants';
import { EProfileBattleStatus } from '~/repository/modules/profile/types/battles';
import type { IProfileBattleMember } from '~/repository/modules/profile/types/battles';

const props = defineProps<IBattlesItemProps>();
const emit = defineEmits<IBattlesItemEmits>();

const isBattleActive = computed(() =>
  [
    EProfileBattleStatus.CREATED,
    EProfileBattleStatus.ROLLED,
    EProfileBattleStatus.STARTED,
    EProfileBattleStatus.FINALIZED,
  ].includes(props.battle.status),
);

const isBattleDone = computed(() => EProfileBattleStatus.FINISHED === props.battle.status);

const { t } = useI18n();

const primaryButtonText = computed(() =>
  isBattleActive.value ? t('profile.battles.battleItem.return') : t('profile.battles.battleItem.createSame'),
);

const secondaryButtonText = computed(() =>
  isBattleActive.value ? t('profile.battles.battleItem.cancel') : t('profile.battles.battleItem.watch'),
);

const battleMemberMap = props.battle.members.reduce(
  (acc, member) => ({ ...acc, [member.id]: member }),
  {} as Record<number, IProfileBattleMember>,
);

const winnerName = computed(() => {
  if (!props.battle.winnerId) return '';
  return battleMemberMap[props.battle.winnerId!]?.name ?? '';
});

const battleMembers = computed(() => {
  return props.battle.members.map((member) => {
    const isWinner = props.battle.winnerId === member.id;
    return { ...member, isWinner };
  });
});

function handlePrimaryButtonClick() {
  if (isBattleActive.value) {
    emit('go-to-battle', props.battle.ulid);
  } else if (isBattleDone.value) {
    emit('duplicate-battle', {
      isBots: props.battle.bots,
      isPrivate: props.battle.private,
      slots: props.battle.slots,
      cases: props.battle.cases,
    });
  } else {
    throw new Error(`Unexpected battle status: ${props.battle.status}`);
  }
}

function handleSecondaryButtonClick() {
  if (isBattleActive.value) {
    emit('cancel-battle', props.battle.ulid, props.battle.price);
  } else if (isBattleDone.value) {
    emit('go-to-battle', props.battle.ulid);
  } else {
    throw new Error(`Unexpected battle status: ${props.battle.status}`);
  }
}

const changeXl = GlobalUtils.Media.changeByMedia('xl');

function formatDataRange(range: [number | string, number | string]): string {
  return GlobalUtils.Currency.formatDataRange(range);
}

function checkWhetherDataRangeValid(range: [number, number]): boolean {
  return BattlesUtils.Validity.checkWhetherDataRangeValid(range);
}
</script>

<style scoped src="./BattlesItem.scss" lang="scss"></style>
