<template>
  <NuxtImg :style="shadowStyle" class="user-avatar-image" :src="src" provider="localProvider" />
</template>

<script setup lang="ts">
import type { IUserAvatarImageProps } from './UserAvatarImage.types';

const props = defineProps<IUserAvatarImageProps>();

const shadowStyle = computed(() => ({ filter: `drop-shadow(0 0 24px ${props.color})` }));
</script>

<style scoped lang="scss" src="./UserAvatarImage.scss"></style>
