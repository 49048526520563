<script setup lang="ts">
import {
  InventoryExchangePopupBottomButtonColorPreset,
  InventoryExchangePopupBottomButtonTextPreset,
  InventoryExchangePopupBottomsButtonFormPreset,
} from '~/features/profile/components/InventoryExchangePopupBottom/InventoryExchangePopupBottom.data';
import { useInventoryStore } from '~/store/inventory/inventory';
import { ProfileInventoryEvents } from '~/repository/amplitude/events/profile/profile-inventory';

const changeMD = GlobalUtils.Media.changeByMedia('md');

const inventoryStore = useInventoryStore();
const { currentExchangeTradeItem, currentTradeItem } = storeToRefs(inventoryStore);

const handleTradeDrop = () => {
  if (currentTradeItem.value && currentExchangeTradeItem.value) {
    ProfileInventoryEvents.changePopupItemClicked({
      'Old Item Name': currentTradeItem.value.name,
      'Old Item Price': Number(currentTradeItem.value.price),
      'Old Item Rarity': currentTradeItem.value.rare,
      'Old Item Type': currentTradeItem.value.type ?? 0,
      'New Item Name': currentExchangeTradeItem.value.name,
      'New Item Price': Number(currentExchangeTradeItem.value.price),
      'New Item Rarity': currentExchangeTradeItem.value.rare,
      'New Item Type': currentExchangeTradeItem.value.type ?? 0,
    });
  }
  inventoryStore.replaceItem({
    inventoryId: currentTradeItem.value!.id,
    replacementId: currentExchangeTradeItem.value!.id,
  });
  inventoryStore.toggleExchangePopup();
};

const isExchangeButtonDisabled = computed<boolean>(() => {
  return !currentExchangeTradeItem.value;
});

const isShowMobileExchangeButton = computed<boolean>(() => {
  return changeMD(true, !isExchangeButtonDisabled.value);
});
</script>

<template>
  <div v-if="isShowMobileExchangeButton" class="inventory-exchange-popup-bottom">
    <span class="inventory-exchange-popup-bottom__text">
      {{ $t('profile.inventoryExchange.popupBottomText') }}
    </span>
    <SharedButtonNew
      :form="InventoryExchangePopupBottomsButtonFormPreset"
      :color="InventoryExchangePopupBottomButtonColorPreset"
      :text-props="InventoryExchangePopupBottomButtonTextPreset"
      :state="{ disabled: isExchangeButtonDisabled }"
      @click="handleTradeDrop"
    >
      {{ $t('profile.inventoryActions.exchange') }}
    </SharedButtonNew>
  </div>
</template>

<style scoped lang="scss" src="./InventoryExchangePopupBottom.scss" />
