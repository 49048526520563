import type { IBasePaginationParams, IAppRequest, IBasePaginationResponse } from '~/types/Shared.types';
import type { CurrencyName } from '~/constants/currency.constants';

export interface IGetProfileWinrateParams {
  /** ID пользователя, по которому запрошен винрейт */
  userId: number;
}

export interface IGetProfileBaseParams {
  /** ID пользователя, по которому запрошен лучший батл */
  userId: number;

  /** Количество участников (используется для фильтрации) */
  memberCount?: number;
}

export interface IGetProfileBattlesParams extends IBasePaginationParams, IGetProfileBaseParams {
  /** Включать ли лучший батл в список */
  withBest?: 1;

  /** Является ли пользователь, по которому запрошены батлы, ботом */
  isBot?: 1;
}

export interface IProfileWinrate {
  win: number;
  lose: number;
}

export interface IProfileBattle {
  /** Является ли батл лучшим (устанавливается на клиенте) */
  isBest?: true;

  /** Участвуют ли боты в батле */
  bots: boolean;

  /** Текст причины отмены батла (заполняется админом) */
  cancellationReason: string | null;

  /** Список кейсов в батле */
  cases: IProfileBattleCase[];

  /** Компактный список кейсов в батле */
  casesCompact: IProfileBattleCase[];

  /** Время завершения батла */
  finishedAt: string | null;

  /** ID создателя батла */
  hostId: number;

  /** Список участников батла */
  members: IProfileBattleMember[];

  /** Принадлежит ли батл текущему пользователю */
  my: boolean;

  /** Цена участия в батле (сумма цен всех поставленных кейсов) */
  price: number;

  /** Скрывать ли батл из списка на время активности (можно перейти только по прямой ссылке) */
  private: boolean;

  /** Количество участников в батле */
  slots: number;

  /** Количество зрителей (используется только когда батл активен) */
  spectators: number;

  /** Время начала батла */
  startedAt: string;

  /** Текущий статус батла */
  status: EProfileBattleStatus;

  /** Количество кейсов в батле */
  total: number | null;

  /** Сумма цен выигранных дропов в батле */
  totalSell: number | null;

  /** ID батла (это не primary key) */
  ulid: string;

  /** ID победителя батла */
  winnerId: number | null;
}

export interface IProfileBattleCase {
  /** Порядковый номер кейса */
  amount: number;

  /** ID кейса */
  caseId: number;

  /** Объект кейса */
  data: IProfileBattleCaseData;
}

export interface IProfileBattleCaseData {
  /** Количество монеток в кейсе (в любых их воплощениях) */
  coins: [number, number];

  /** Валюта цены кейса */
  currency: typeof CurrencyName;

  /** Отображаемое имя кейса */
  dispName: string;

  /** Дополнительное изображение кейса (отображается при наведении) */
  hoverImage: string;

  /** ID кейса */
  id: number;

  /** Основное изображение кейса */
  image: string;

  /** Объект лейбла кейса */
  label: IProfileBattleCaseDataLabel;

  /** Диапазон допустимых значений кейсов за одну крутку */
  multicastRange: [number, number] | null;

  /** Мультификс-кейс или нет */
  multifix: boolean | null;

  /** Название кейса */
  name: string;

  /** Старая цена кейса */
  oldPrice: number;

  /** Цена кейса */
  price: number;

  /** Тип кейса */
  type: string; // TODO: сделать или найти enum
}

export interface IProfileBattleCaseDataLabel {
  /** Цвет лейбла */
  color: string;

  /** Отображать ли лейбл */
  freeOpens: 1 | 0;

  /** Имя лейбла */
  name: string;

  /** Тип лейбла */
  type: string; // TODO: сделать или найти enum
}

export interface IProfileBattleMember {
  /** ID пользователя */
  id: number;

  /** URL аватара участника */
  avatar: string;

  /** Является ли участник ботом */
  bot: boolean;

  /** Уровень участника */
  level: number | null;

  /** Является ли участник текущим пользователем */
  me: boolean;

  /** Отображаемое имя участника */
  name: string;

  /** Нажал ли пользователь "готов" */
  ready: number | null;

  /** Порядковый номер участника в батле */
  slot: number;

  /** ID участника */
  userId: number;
}

export enum EProfileBattleStatus {
  /** Батл отменен */
  CANCELLED = 'cancelled',

  /** Батл создан, но ещё не начат */
  CREATED = 'created',

  /** Батл готовится к завершению, идут подсчёты */
  FINALIZED = 'finalized',

  /** Батл завершен и не активен */
  FINISHED = 'finished',

  /** Батл в состоянии прокрутки */
  ROLLED = 'rolled',

  /** Батл начался и активен */
  STARTED = 'started',
}

export type TGetBattlesResponseDTO = IAppRequest<IBasePaginationResponse<IProfileBattle>>;
export type TGetBestBattleResponseDTO = IAppRequest<IProfileBattle>;
export type TGetProfileWinrateDTO = IAppRequest<IProfileWinrate>;
