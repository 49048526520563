import type { IStatBattlesState, TOutcome } from './StatBattles.types';
import { Currency } from '~/constants/currency.constants';
import { useProfilePresentationStore } from '~/features/profile/store/profilePresentation/ProfilePresentation.store';
import type { IGetStatBattlesParams } from '~/repository/modules/profile/types/statistics';
import { useUserStore } from '~/store/user/user.store';

const { calculatePercents } = GlobalUtils.Maths;

function padArray<T, P>(arr: T[], targetLength: number, padValue: P): T[] {
  return arr.length < targetLength ? [...arr, ...Array(targetLength - arr.length).fill(padValue)] : arr;
}

function boolToOutcome(value: boolean): TOutcome {
  return value ? 'W' : 'L';
}

export const useStatBattlesStore = defineStore('profile/statBattles', () => {
  const route = useRoute();
  const { user } = useUserStore();
  const { $api } = useNuxtApp();
  const profilePresentationStore = useProfilePresentationStore();

  const isError = ref(false);
  const isLoaded = ref(false);
  const data = ref({} as IStatBattlesState);

  watch(
    () => profilePresentationStore.currentBattlesFilter,
    async (filter) => {
      try {
        isLoaded.value = false;
        await fetch(filter);
        isLoaded.value = true;
      } catch {
        isError.value = true;
      }
    },
  );

  function fetch(memberCount?: number) {
    return GlobalUtils.Api.handleRequest(async () => {
      const params: IGetStatBattlesParams = {
        userId: Number(route.params.userId),
        isBot: false,
        ...(memberCount ? { memberCount } : {}),
      };
      try {
        const resp = await $api.profile.getStatBattles(params);

        if (resp.status) {
          if (!user) return;
          const { isFake, finance } = user;
          const currentCurrency = isFake ? Currency.DIAMOND : Currency[finance.currency];
          data.value = {
            allCount: formatNumber(resp.data.allCount, { dashIfZero: true }),
            lastFiveWinner: padArray(resp.data.lastFiveWiner.map(boolToOutcome), 5, '-'),
            // TODO не нужно обрабатывать валюту на стороне сервера, это делает в SharedCurrency, а также использовать useFormatCurrency
            maxDrop: resp.data.maxDrop
              ? formatNumber(parseFloat(resp.data.maxDrop), { dashIfZero: true }) + ' ' + currentCurrency
              : '-',
            winCount: formatNumber(resp.data.winCount, { dashIfZero: true }),
            winRate:
              resp.data.winCount && resp.data.allCount
                ? calculatePercents(resp.data.winCount, resp.data.allCount).toFixed(2) + '%'
                : '-',
          };

          isLoaded.value = true;
        }
      } catch {
        isError.value = true;
      }
    });
  }

  return { fetch, isLoaded, data };
});
