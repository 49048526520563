<template>
  <div :class="['user-avatar', containerClasses]" @click="$emit('generate-level')">
    <ProfileUserAvatarLevel
      v-if="!isBot"
      :can-open-popup="isCurrentUser"
      :level="userLevel"
      :color="levelColor"
      @open-popup="$emit('open-exp-popup')"
    />
    <ProfileUserAvatarExperience
      v-if="!isBot"
      :current-experience="currentExperience ?? 0"
      :experience-to-next-level="experienceToNextLevel ?? 0"
      :can-open-popup="isCurrentUser && wasGeneratedLevel"
    />
    <div class="user-avatar__content">
      <ProfileUserAvatarAnimatedCircles :variant="statusToCircleVariantMap[status]" :color="levelColor" />
      <ProfileUserAvatarImage
        v-show="status === EUserAvatarContainerStatus.DEFAULT"
        :src="avatarSrc"
        :color="levelColor"
      />
      <div :class="['user-avatar__label', labelClasses]">
        {{ labelText }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  EUserAvatarContainerStatus,
  type IUserAvatarContainerEmits,
  type IUserAvatarContainerProps,
} from './UserAvatarContainer.types';
import { ECircleVariant } from '~/features/profile/components/UserAvatarAnimatedCircles/UserAvatarAnimatedCircles.types';
import { getLevelVariable } from '~/components/HeaderProfile/HeaderProfile.data';

const { t } = useI18n();

const props = defineProps<IUserAvatarContainerProps>();
defineEmits<IUserAvatarContainerEmits>();

const statusToCircleVariantMap = {
  [EUserAvatarContainerStatus.DEFAULT]: ECircleVariant.DEFAULT_STATIC,
  [EUserAvatarContainerStatus.READY_TO_GENERATE]: ECircleVariant.GRADIENT_ROTATING,
  [EUserAvatarContainerStatus.GENERATING]: ECircleVariant.RAINBOW_ROTATING,
  [EUserAvatarContainerStatus.DONE_GENERATING]: ECircleVariant.RAINBOW_ORANGE_FILLING,
};

const containerClasses = computed<Record<string, boolean>>(() => ({
  'user-avatar--default': props.status === EUserAvatarContainerStatus.DEFAULT,
  'user-avatar--idle': props.status === EUserAvatarContainerStatus.READY_TO_GENERATE,
  'user-avatar--generating': props.status === EUserAvatarContainerStatus.GENERATING,
}));

const labelClasses = computed<Record<string, boolean>>(() => ({
  'user-avatar__label--active': props.status !== EUserAvatarContainerStatus.READY_TO_GENERATE,
  'fade-out': props.status === EUserAvatarContainerStatus.DONE_GENERATING,
}));

const labelText = computed<string>(() => {
  const statusTexts = {
    [EUserAvatarContainerStatus.READY_TO_GENERATE]: t('profile.userInfo.generateLevel.readyToGenerate'),
    [EUserAvatarContainerStatus.GENERATING]: t('profile.userInfo.generateLevel.generating'),
    [EUserAvatarContainerStatus.DONE_GENERATING]: t('profile.userInfo.generateLevel.doneGenerating'),
  } as Record<EUserAvatarContainerStatus, string>;
  return statusTexts[props.status];
});

const levelColor = computed(() => {
  if (!props.wasGeneratedLevel) return 'var(--main-purple-600)';
  if (props.userLevel > 119) {
    // уровни выше 119 представлены списком цветов
    return `linear-gradient(${getLevelVariable(props.userLevel)})`;
  }
  return getLevelVariable(props.userLevel);
});
</script>

<style scoped lang="scss" src="./UserAvatarContainer.scss" />
