/* eslint-disable @typescript-eslint/naming-convention */
import type { EInventoryItemSource } from '~/repository/modules/inventory/inventory.types';
import type { IProfileTypeEvent } from '~/repository/amplitude/types/profile/profile-common';
import type { TDropItemType } from '~/types/cases/dropItem';

export enum EProfileInventoryEvents {
  SELL_ALL_ITEMS_CLICKED = 'Profile - Sell All Items Clicked',
  SELL_ITEM_CLICKED = 'Profile - Sell Item Clicked',
  CHANGE_SELL_ITEM_CLICKED = 'Change Item - Sell Item Clicked',
  ITEMS_TO_UPGRADE_CLICKED = 'Profile - Items To Upgrade Clicked',
  ALL_ITEMS_SWITCHED = 'Profile - Switch All Items Clicked',
  CASE_PROMOTION_CLICKED = 'Profile - Case Promotion Clicked',
  WITHDRAWAL_ITEM_CLICKED = 'Profile - Withdraw Item Clicked',
  ITEM_SOURCE_CLICKED = 'Profile - Item Source Clicked',
  ACCEPT_TRADE_CLICKED = 'Profile - Accept Trade Clicked',
  CHANGE_ITEM_TRIGGERED = 'Change Item - Change Item Triggered',
  CHANGE_ITEM_CLICKED = 'Change Item - Change Item Clicked',
  HINT_CLICKED = 'Change Item - Hint Clicked',
  WITHDRAW_FEW_ITEMS_CLICKED = 'Profile - Withdraw Few Items Clicked',
  WITHDRAW_FEW_ITEMS_CANCELED = 'Profile - Withdraw Few Items Canceled',
  BACK_TO_INVENTORY_CLICKED = 'Change Item - Back To Inventory Clicked',
  BEST_DROP_CLICKED = 'Profile - Best Drop Clicked',
}

export interface IProfileInventoryEventsItemBase {
  'Item Name': string;
  'Item Price': number;
  'Item Rarity'?: string;
  'Item Type'?: TDropItemType;
}

interface IProfileInventoryEventsItemCountBase {
  'Item Count': number;
  'Item Price': number;
}

export interface ISellAllItemsClickedEvent extends IProfileInventoryEventsItemCountBase {}

export interface ISellItemClickedEvent extends IProfileInventoryEventsItemBase {
  'Item Rarity': string;
  'Item Type': TDropItemType;
}

export interface IItemsToUpgradeClickedEvent extends IProfileInventoryEventsItemCountBase {}

export interface ICasePromotionClickedEvent {
  'Case Name': string;
  'Case Price': number;
}

export interface IWithdrawalItemClickedEvent extends IProfileInventoryEventsItemBase {
  'Item Rarity': string;
}

export interface IItemSourceClickedEvent extends IProfileInventoryEventsItemBase {
  'Source Type': EInventoryItemSource;
  'Item Status': 'New' | 'Old';
}

export interface IChangeItemTriggeredEvent extends IProfileInventoryEventsItemBase {
  'Item Rarity': string;
}

export interface IChangeItemClickedEvent extends IProfileInventoryEventsItemBase {
  'Item Rarity': string;
}

export interface IWithdrawFewItemsClickedEvent extends IProfileInventoryEventsItemCountBase {}

export interface IChangeSellItemClickedEvent extends IProfileInventoryEventsItemBase {}

export interface IChangePopupChangeItemClickedEvent {
  'Old Item Name': string;
  'Old Item Price': number;
  'Old Item Rarity': string;
  'Old Item Type': TDropItemType;
  'New Item Name': string;
  'New Item Price': number;
  'New Item Rarity': string;
  'New Item Type': TDropItemType;
}

export interface IBestDropClickedEvent extends IProfileInventoryEventsItemBase, IProfileTypeEvent {}
