<template>
  <div :class="containerClasses">
    {{ displayExperience }}
    <SvgoInfo v-show="canOpenPopup" :width="12" :height="12" @click="$emit('open-exp-popup')" />
  </div>
</template>

<script setup lang="ts">
import type { IUserAvatarExperienceEmits, IUserAvatarExperienceProps } from './UserAvatarExperience.types';

const props = defineProps<IUserAvatarExperienceProps>();
defineEmits<IUserAvatarExperienceEmits>();

const displayExperience = computed<`${string} / ${string} XP`>(() => {
  return `${Math.max(0, props.currentExperience)} / ${Math.max(0, props.experienceToNextLevel)} XP`;
});
const isSliding = ref(false);

const containerClasses = computed<[string, Record<string, boolean>]>(() => [
  'user-avatar-experience',
  { 'user-avatar-experience--slide-left': isSliding.value },
]);

watch([() => props.currentExperience, () => props.experienceToNextLevel], () => {
  isSliding.value = true;
  setTimeout(() => {
    isSliding.value = false;
  }, 2_000); // анимация слайда длится 1 секунду, убираем анимированный класс через 2
});
</script>

<style scoped lang="scss" src="./UserAvatarExperience.scss"></style>
