<template>
  <div class="upgrades-item">
    <div v-if="isMobile" class="upgrades-item__result">
      <span class="upgrades-item__result-text" :class="{ 'upgrades-item__result-text--win': win }">
        {{ wonText }}
      </span>
      <span class="upgrades-item__result-numbers">
        {{ numbersText }}
      </span>
    </div>
    <SharedKitDropItemNew :is-mobile="isMobile" :inventory="prize" class="upgrades-item__prize" />
    <div class="upgrades-item__content" :class="{ 'upgrades-item__content--active': isMobile && isActive }">
      <div class="upgrades-item__info">
        <div v-if="!isMobile" class="upgrades-item__result">
          <span class="upgrades-item__result-text" :class="{ 'upgrades-item__result-text--win': win }">
            {{ wonText }}
          </span>
          <span class="upgrades-item__result-numbers">
            {{ numbersText }}
          </span>
        </div>
        <div class="upgrades-item__items">
          <SvgoArrowLeftIcon
            v-if="isMobile"
            :width="16"
            :height="16"
            class="upgrades-item__icon"
            :class="{ 'upgrades-item__icon--active': isActive }"
            @click="handleActiveState"
          />
          <span class="upgrades-item__items-quantity">
            {{ quantityText }}
          </span>
          <span class="upgrades-item__items-price">
            {{ totalItemsPrice }}
          </span>
        </div>
      </div>
      <div class="upgrades-item__list-wrapper">
        <div
          v-show="!isMobile || (isMobile && isActive)"
          class="upgrades-item__list"
          :class="{ 'upgrades-item__list--active': isActive }"
        >
          <SharedKitDropItemNew
            v-for="item in items"
            :key="item.id"
            :inventory="item"
            class="upgrades-item__list-item"
          />
        </div>
      </div>
    </div>
    <SvgoArrowLeftIcon
      v-if="!isMobile && items.length > DROPS_QUANTITY"
      :width="40"
      :height="40"
      class="upgrades-item__icon"
      :class="{ 'upgrades-item__icon--active': isActive }"
      @click="handleActiveState"
    />
  </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user/user.store';
import type { IUpgradeItem } from '~/repository/modules/profile/types/upgrades';
import { ProfileUpgradesEvents } from '~/repository/amplitude/events/profile/profile-upgrades';
import { EProfileType } from '~/repository/amplitude/types/profile/profile-common';

const DROPS_QUANTITY = 5;

const props = defineProps<IUpgradeItem>();

const { t } = useI18n();

const { prize, win, percent, items, index } = toRefs(props);

const wonText = computed(() => {
  return win.value ? t('profile.upgrades.upgradeItem.win') : t('profile.upgrades.upgradeItem.lost');
});

const numbersText = computed(() => {
  return `${percent.value}% (x${index.value})`;
});

const quantityText = computed(() => {
  const translation = t('profile.upgrades.upgradeItem.itemsQuantity', {
    quantity: items.value.length,
    itemsDeclination: t('profile.upgrades.upgradeItem.itemsDeclination', items.value.length),
  });
  return `${items.value.length} ${translation}`;
});

const totalItemsPrice = computed(() => {
  const amount = items.value.reduce((total, item) => {
    return total + (Number(item.price) || 0);
  }, 0);
  return useFormatCurrency(amount, prize.value.currency);
});

const changeMD = GlobalUtils.Media.changeByMedia('md');

const isMobile = computed(() => {
  return changeMD(false, true);
});

const isActive = ref(false);

const userStore = useUserStore();
const { isCurrentUser } = storeToRefs(userStore);

const handleActiveState = () => {
  ProfileUpgradesEvents.fullUpgradeClicked({
    'Profile Type': isCurrentUser.value ? EProfileType.PERSONAL : EProfileType.FOREIGN,
  });
  isActive.value = !isActive.value;
};
</script>

<style scoped lang="scss" src="./UpgradesItem.scss" />
