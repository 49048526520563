<template>
  <SharedModal v-model="model" :radius="pageStyles.radius" :hide-close-button="hideCloseButton">
    <div class="wrapper">
      <NuxtImg class="img" :src="getS3Image('case', 'image_default', 'png')" provider="localProvider" />
      <div class="content">
        <SharedTitle
          class="title"
          variant="band"
          :size="pageStyles.titleSize"
          :text-props="{
            fontFamily: FontFamily.REAVER,
            weight: FontWeights.BOLD,
            letterSpacing: '2.4px',
          }"
        >
          КАК РАБОТАЕТ <br />
          СИСТЕМА УРОВНЕЙ?
        </SharedTitle>
        <section class="text">
          <p v-for="(item, i) in $tm('profile.levelsGuide.textItems')" :key="item" class="text-item">
            <I18nT :keypath="'profile.levelsGuide.textItems.' + i" tag="div">
              <template #freeCases>
                <span class="gold"> {{ $t('profile.levelsGuide.freeCases') }}</span>
              </template>
              <template #timer>
                <span class="text-item-timer">
                  <I18nT keypath="profile.levelsGuide.timer" tag="span">
                    <template #days>{{ refinedTime.days }}</template>
                    <template #hours>{{ refinedTime.hours }}</template>
                    <template #minutes>{{ refinedTime.days }}</template>
                  </I18nT>
                </span>
              </template>
            </I18nT>
          </p>
        </section>
        <SharedKitButton
          v-bind="
            presetBtn('primary', {
              colorPreset: ColorPresets.DOTA.SECONDARY,
              sizePreset: SizePresets.L,
              typePreset: TypePresets.ROUND,
            })
          "
          class="cleared-btn"
          @click="model = false"
        >
          Понятно
        </SharedKitButton>
      </div>
    </div>
  </SharedModal>
</template>

<script setup lang="ts">
import type { IDailyCasesHowItWorksPopupProps, IPageStyles } from './HowItWorksPopup.types';
import { FontFamily, FontSizes, FontWeights } from '~/types/SharedFont.types';
import { Breakpoints } from '~/constants/media.constants';
import { createCasesButtonPresetor } from '~/features/cases/presets/buttons.preset';
import { ColorPresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { TypePresets } from '~/components/SharedIcon/SharedIcon.presets';
import type { ITimerData } from '~/composables/useTimer';

const viewport = useViewport();
const props = defineProps<IDailyCasesHowItWorksPopupProps>();

const changeXl = GlobalUtils.Media.changeByMedia('xl');

const pageStyles = computed<IPageStyles>(() => ({
  radius: viewport.isGreaterThan(Breakpoints.md) ? 16 : 8,
  titleSize: viewport.isGreaterThan(Breakpoints.md) ? FontSizes.LARGE_2XL : FontSizes.LARGE_XL,
}));

const presetBtn = createCasesButtonPresetor(changeXl);

const hideCloseButton = computed<boolean>(() => viewport.isLessThan(Breakpoints.md));

const model = defineModel<boolean>('modelValue', {
  required: true,
});

const { timer } = toRefs(props);

const { timerData, start } = useTimer(timer);

onMounted(start);

const refinedTime = computed<Pick<ITimerData, 'days' | 'hours' | 'minutes'>>(() => {
  return {
    days: timerData.value.days,
    hours: timerData.value.hours,
    minutes: timerData.value.minutes,
  };
});
</script>

<style scoped lang="scss" src="./HowItWorksPopup.scss" />
