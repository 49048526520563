import type {
  ISharedButtonNewColorPreset,
  ISharedButtonNewFormPreset,
  ISharedButtonTextPreset,
} from '~/types/SharedButtonNew.types';
import battles from '~/assets/images/Profile/Inventory/battles.png';
import cases from '~/assets/images/Profile/Inventory/cases.png';
import contract from '~/assets/images/Profile/Inventory/contract.png';
import exchange from '~/assets/images/Profile/Inventory/exchange.png';
import upgrade from '~/assets/images/Profile/Inventory/upgrade.png';
import {
  defaultButtonColorPreset,
  defaultMediumButtonTextPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';
import { Colors } from '~/constants/colors.constants';
import type { IInventory } from '~/repository/modules/inventory/inventory.types';
import { EInventoryItemSource, EInventoryItemStatus } from '~/repository/modules/inventory/inventory.types';
import type {
  IInventoryItemAction,
  IInventoryItemActionArgs,
  IInventoryItemCameFrom,
  IInventoryItemStatus,
} from '~/features/profile/components/InventoryItem/InventoryItem.types';
import {
  SharedButtonNew,
  SvgoCansellSelected,
  SvgoFinanceAndMathPlus,
  SvgoCoin,
  SvgoContractFill,
  SvgoQuestionIcon,
  SvgoReplaced,
  SvgoReplacedRounded,
  SvgoSteamIcon,
  SvgoTake,
  SvgoTimer,
  SvgoUpgradeIconNoBg,
} from '#components';
import { useInventoryStore } from '~/store/inventory/inventory';
import { useInventoryWsStore } from '~/store/inventory/inventory.ws';
import { ProfileInventoryEvents } from '~/repository/amplitude/events/profile/profile-inventory';

export const getInventoryItemData = () => {
  const {
    $i18n: { t },
  } = useNuxtApp();
  const inventoryStore = useInventoryStore();
  const { currentTradeItem, changeItemList, currentInventoryExchangePopupTime } = storeToRefs(inventoryStore);

  const inventoryWsStore = useInventoryWsStore();

  const InventoryItemButtonFormPreset: ISharedButtonNewFormPreset = {
    ...defaultMiddleButtonFormPreset,
    height: '32px',
    minWidth: '152px',
    radius: 'var(--border-radius-3xs)',
    width: '152px',
  };

  const InventoryItemButtonTakeColorPreset: ISharedButtonNewColorPreset = {
    ...defaultButtonColorPreset,
    color: Colors.NEUTRAL.WHITE_15,
    colorHovered: Colors.NEUTRAL.WHITE_30,
  };

  const InventoryItemButtonTradeColorPreset: ISharedButtonNewColorPreset = {
    ...defaultButtonColorPreset,
    color: Colors.MAIN.PURPLE_200,
    colorHovered: Colors.MAIN.PURPLE_100,
  };

  const InventoryItemButtonTimerPreset: ISharedButtonNewColorPreset = {
    ...defaultButtonColorPreset,
    color: 'rgba(var(--neutral-black), 0)',
    colorHovered: 'rgba(var(--neutral-black), 0)',
  };

  const InventoryItemButtonSellColorPreset: ISharedButtonNewColorPreset = {
    ...defaultButtonColorPreset,
    color: Colors.MAIN.GOLD,
    colorHovered: Colors.NEUTRAL.WHITE,
  };

  const InventoryItemButtonTakeTextPreset: ISharedButtonTextPreset = {
    ...defaultMediumButtonTextPreset,
    size: 'var(--font-size-s)',
  };

  const InventoryItemButtonSellTextPreset: ISharedButtonTextPreset = {
    ...defaultMediumButtonTextPreset,
    color: 'var(--neutral-black)',
    size: 'var(--font-size-s)',
  };

  const oldStatuses: EInventoryItemStatus[] = [
    EInventoryItemStatus.CONTRACTED,
    EInventoryItemStatus.ACCEPTED,
    EInventoryItemStatus.AUTOSELLED,
    EInventoryItemStatus.CHANGED,
    EInventoryItemStatus.SOLD,
    EInventoryItemStatus.UPGRADED,
  ];

  const handleItemActionClicked = (inventory: IInventory, actionType: 'change' | 'sell' | 'withdraw') => {
    const eventData = {
      'Item Name': inventory.name,
      'Item Price': Number(inventory.price),
      'Item Rarity': inventory.rare,
      'Item Type': inventory.type ?? 0,
    };

    switch (actionType) {
      case 'change':
        ProfileInventoryEvents.changeItemClicked(eventData);
        break;
      case 'sell':
        ProfileInventoryEvents.sellItemClicked(eventData);
        break;
      case 'withdraw':
        ProfileInventoryEvents.withdrawalItemClicked(eventData);
        break;
    }
  };

  const deskTopActionsData = {
    preparing: (refinedTime: { hours: string; minutes: string; seconds: string }): IInventoryItemAction[] => [
      {
        colorPreset: InventoryItemButtonTimerPreset,
        component: SharedButtonNew,
        text:
          refinedTime.hours !== '00'
            ? `${refinedTime.hours} : ${refinedTime.minutes} : ${refinedTime.seconds}`
            : `${refinedTime.minutes} : ${refinedTime.seconds}`,
      },
      {
        colorPreset: InventoryItemButtonTakeColorPreset,
        component: SharedButtonNew,
        text: t('preparingItem'),
        textPreset: InventoryItemButtonTakeTextPreset,
      },
    ],
    exchanging: (refinedTime: { hours: string; minutes: string; seconds: string }): IInventoryItemAction[] => [
      {
        colorPreset: InventoryItemButtonTimerPreset,
        component: SharedButtonNew,
        text:
          refinedTime.hours !== '00'
            ? `${refinedTime.hours} : ${refinedTime.minutes} : ${refinedTime.seconds}`
            : `${refinedTime.minutes} : ${refinedTime.seconds}`,
      },
      {
        colorPreset: InventoryItemButtonTakeColorPreset,
        component: SharedButtonNew,
        text: t('replaceItem'),
        onClick: (args: IInventoryItemActionArgs) => {
          if (args.inventory) {
            handleItemActionClicked(args.inventory, 'change');
          }
          currentTradeItem.value = args.inventory;
          changeItemList.value = args.inventory?.replacementItems ?? [];
          currentInventoryExchangePopupTime.value = args.inventory?.timerData ?? 0;
          inventoryStore.isExchangePopupVisible = true;
        },
        textPreset: InventoryItemButtonTakeTextPreset,
      },
    ],
    vendorWaiting: (refinedTime: { hours: string; minutes: string; seconds: string }): IInventoryItemAction[] => [
      {
        colorPreset: InventoryItemButtonTimerPreset,
        component: SharedButtonNew,
        text:
          refinedTime.hours !== '00'
            ? `${refinedTime.hours} : ${refinedTime.minutes} : ${refinedTime.seconds}`
            : `${refinedTime.minutes} : ${refinedTime.seconds}`,
      },
      {
        colorPreset: InventoryItemButtonTakeColorPreset,
        component: SharedButtonNew,
        text: t('waitingForSeller'),
        textPreset: InventoryItemButtonTakeTextPreset,
      },
    ],
    send: (refinedTime: { hours: string; minutes: string; seconds: string }): IInventoryItemAction[] => [
      {
        colorPreset: InventoryItemButtonTimerPreset,
        component: SharedButtonNew,
        text:
          refinedTime.hours !== '00'
            ? `${refinedTime.hours} : ${refinedTime.minutes} : ${refinedTime.seconds}`
            : `${refinedTime.minutes} : ${refinedTime.seconds}`,
      },
      {
        colorPreset: InventoryItemButtonTradeColorPreset,
        component: SharedButtonNew,
        text: t('get'),
        onClick: (args: IInventoryItemActionArgs) => {
          inventoryWsStore.getItem(args.id);
        },
        textPreset: InventoryItemButtonTakeTextPreset,
      },
    ],
    default: [
      {
        colorPreset: InventoryItemButtonTakeColorPreset,
        component: SharedButtonNew,
        onClick: async (args: IInventoryItemActionArgs) => {
          if (args.inventory) {
            handleItemActionClicked(args.inventory, 'withdraw');
          }
          await inventoryStore.sendItem(args.id, args.isType3 ? undefined : 'senditem');
        },
        text: t('profile.inventoryActions.take'),
        textPreset: InventoryItemButtonTakeTextPreset,
      },
      {
        colorPreset: InventoryItemButtonSellColorPreset,
        component: SharedButtonNew,
        onClick: async (args: IInventoryItemActionArgs) => {
          if (args.inventory) {
            handleItemActionClicked(args.inventory, 'sell');
          }
          await inventoryStore.sellItem(args.id);
        },
        text: t('profile.inventoryActions.sell'),
        textPreset: InventoryItemButtonSellTextPreset,
      },
    ],
  };

  const mobileActionsData = {
    preparing: [
      {
        class: 'timer',
        component: SvgoTimer,
      },
    ],
    exchanging: [
      {
        class: 'replace',
        component: SvgoReplacedRounded,
        onClick: (args: IInventoryItemActionArgs) => {
          if (args.inventory) {
            handleItemActionClicked(args.inventory, 'change');
          }
          currentTradeItem.value = args.inventory;
          changeItemList.value = args.inventory?.replacementItems ?? [];
          currentInventoryExchangePopupTime.value = args.inventory?.timerData ?? 0;
          inventoryStore.isExchangePopupVisible = true;
        },
      },
    ],
    vendorWaiting: [
      {
        class: 'timer',
        component: SvgoTimer,
      },
    ],
    send: [
      {
        class: 'steam',
        component: SvgoSteamIcon,
        onClick: (args: IInventoryItemActionArgs) => {
          ProfileInventoryEvents.acceptTradeClicked();
          inventoryWsStore.getItem(args.id);
        },
      },
    ],
    default: [
      {
        class: 'take',
        component: SvgoTake,
        onClick: async (args: IInventoryItemActionArgs) => {
          if (args.inventory) {
            handleItemActionClicked(args.inventory, 'withdraw');
          }
          await inventoryStore.sendItem(args.id, args.isType3 ? undefined : 'senditem');
        },
      },
      {
        class: 'buy',
        component: SvgoCoin,
        onClick: async (args: IInventoryItemActionArgs) => {
          if (args.inventory) {
            handleItemActionClicked(args.inventory, 'sell');
          }
          await inventoryStore.sellItem(args.id);
        },
      },
    ],
  };

  const type3ActionsData = (inventory: IInventory): IInventoryItemAction[] => {
    return inventory.isSelected
      ? [
          {
            class: 'cancel',
            component: SvgoCansellSelected,
            onClick: (args: IInventoryItemActionArgs) => {
              if (args.inventory) {
                inventoryStore.toggleSelectItemState(args.inventory);
              }
            },
          },
        ]
      : [
          {
            class: 'plus',
            component: SvgoFinanceAndMathPlus,
            onClick: (args: IInventoryItemActionArgs) => {
              if (args.inventory) {
                inventoryStore.toggleSelectItemState(args.inventory);
              }
            },
          },
        ];
  };

  const getItemStatus = (inventory: IInventory): IInventoryItemStatus => {
    switch (inventory.status) {
      case EInventoryItemStatus.SOLD:
        return {
          color: Colors.MAIN.GOLD,
          component: SvgoCoin,
          text: t('profile.inventoryExchange.itemStatus.sold'),
        };
      case EInventoryItemStatus.CONTRACTED:
        return {
          color: '#ff0063',
          component: SvgoContractFill,
          text: t('profile.inventoryExchange.itemStatus.contracted'),
        };
      case EInventoryItemStatus.EXCHANGING:
        return {
          color: Colors.NEUTRAL.WHITE_65,
          component: SvgoQuestionIcon,
          text: t('profile.inventoryItem.itemUnavailable'),
        };
      case EInventoryItemStatus.EXCHANGED:
        return {
          color: Colors.NEUTRAL.WHITE_65,
          component: SvgoReplaced,
          text: t('profile.inventoryExchange.itemStatus.exchanged'),
        };
      case EInventoryItemStatus.STEAM_WITHDRAWN:
        return {
          color: Colors.MAIN.PURPLE_50,
          component: SvgoTake,
          text: t('profile.inventoryExchange.itemStatus.steamWithdrawn'),
        };
      case EInventoryItemStatus.UPGRADED:
        return {
          color: Colors.ADDITIONAL.GREEN,
          component: SvgoUpgradeIconNoBg,
          text: t('profile.inventoryExchange.itemStatus.upgraded'),
        };
      case EInventoryItemStatus.PREPARING:
        return {
          color: Colors.NEUTRAL.WHITE_65,
          text: t('profile.inventoryExchange.itemStatus.repairing'),
        };
      case EInventoryItemStatus.VENDOR_WAITING:
        return {
          color: Colors.NEUTRAL.WHITE_65,
          text: t('profile.inventoryExchange.itemStatus.vendorWaiting'),
        };
      case EInventoryItemStatus.CONFIRM_STEAM:
        return {
          color: Colors.NEUTRAL.WHITE_65,
          text: t('profile.inventoryExchange.itemStatus.confirmSteam'),
        };
      default:
        return {
          color: '',
          component: null,
          text: '',
        };
    }
  };

  const handleSourceTypeClicked = (inventory: IInventory) => {
    ProfileInventoryEvents.itemSourceClicked({
      'Item Rarity': inventory.rare,
      'Item Type': inventory.type ?? 0,
      'Item Name': inventory.name,
      'Item Price': Number(inventory.price),
      'Source Type': inventory.from?.from ?? EInventoryItemSource.CASES,
      'Item Status': oldStatuses.includes(inventory.status) ? 'Old' : 'New',
    });
  };

  const getCameFrom = (inventory: IInventory): IInventoryItemCameFrom => {
    switch (inventory.from?.from) {
      case EInventoryItemSource.BATTLES:
        return {
          image: battles,
          link: `${useLinks().BATTLES}/${inventory.from.battleUlid ?? ''}`,
          text: t('profile.inventoryExchange.itemOrigin.battle'),
          onClick: () => handleSourceTypeClicked(inventory),
        };
      case EInventoryItemSource.CASES:
        return {
          image: cases,
          link: `${useLinks().OPEN_CASE}/${inventory.from.case ?? ''}`,
          text: t('profile.inventoryExchange.itemOrigin.case'),
          onClick: () => handleSourceTypeClicked(inventory),
        };
      case EInventoryItemSource.CONTRACTS:
        return {
          image: contract,
          link: '/contract',
          text: t('profile.inventoryExchange.itemOrigin.contract'),
          onClick: () => handleSourceTypeClicked(inventory),
        };
      case EInventoryItemSource.EXCHANGE:
        return {
          image: exchange,
          link: '',
          text: t('profile.inventoryExchange.itemOrigin.exchange'),
          onClick: () => handleSourceTypeClicked(inventory),
        };
      case EInventoryItemSource.UPGRADE:
        return {
          image: upgrade,
          link: '/upgrades',
          text: t('profile.inventoryExchange.itemOrigin.upgrade'),
          onClick: () => handleSourceTypeClicked(inventory),
        };
      default:
        return {
          image: '',
          link: '',
          text: '',
        };
    }
  };

  return {
    InventoryItemButtonFormPreset,
    oldStatuses,
    deskTopActionsData,
    mobileActionsData,
    getItemStatus,
    getCameFrom,
    type3ActionsData,
  };
};
