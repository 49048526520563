export enum ECircleVariant {
  DEFAULT_STATIC,
  GRADIENT_ROTATING,
  RAINBOW_ROTATING,
  RAINBOW_ORANGE_FILLING,
}

export interface IUserAvatarCirclesProps {
  variant: ECircleVariant;
  color: string;
}
