<script setup lang="ts">
import type {
  IInventoryItem,
  IInventoryItemAction,
  IInventoryItemCameFrom,
  IInventoryItemProps,
  IInventoryItemStatus,
} from './InventoryItem.types';
import InventoryItemInfo from '~/features/profile/components/InventoryItemInfo/InventoryItemInfo.vue';
import InventoryItemActions from '~/features/profile/components/InventoryItemActions/InventoryItemActions.vue';
import { getInventoryItemData } from '~/features/profile/components/InventoryItem/InventoryItem.data';
import { useInventoryStore } from '~/store/inventory/inventory';
import type { IInventory } from '~/repository/modules/inventory/inventory.types';
import { EInventoryItemStatus } from '~/repository/modules/inventory/inventory.types';
import { dropItemTypes } from '~/types/cases/dropItem';
import { useInventoryWsStore } from '~/store/inventory/inventory.ws';
import { useUserStore } from '~/store/user/user.store';
import { ProfileInventoryEvents } from '~/repository/amplitude/events/profile/profile-inventory';

const changeXl = GlobalUtils.Media.changeByMedia('xl');
const isMobile = computed(() => {
  return changeXl(false, true);
});

const {
  InventoryItemButtonFormPreset,
  oldStatuses,
  deskTopActionsData,
  mobileActionsData,
  getItemStatus,
  getCameFrom,
  type3ActionsData,
} = getInventoryItemData();

const props = withDefaults(defineProps<IInventoryItemProps>(), {
  inventory: () => ({}) as IInventory,
});

const { inventory } = toRefs(props);
const inventoryStore = useInventoryStore();
const inventoryWsStore = useInventoryWsStore();

const { user } = storeToRefs(useUserStore());

const declareColorOfItem = (item: IInventoryItem) => {
  return GlobalUtils.Colors.getColorsRarity((item as IInventoryItem).rare);
};

const isItemOld = computed<boolean>(() => {
  return oldStatuses.includes(inventory.value.status);
});

const isItemType3 = computed(() => {
  return inventory.value.type === dropItemTypes['3'];
});

const itemStatus = computed<IInventoryItemStatus>(() => {
  return getItemStatus(inventory.value);
});

const cameFrom = computed<IInventoryItemCameFrom>(() => {
  return getCameFrom(inventory.value);
});

const cameFromImageSize = computed<number>(() => {
  return isItemOld.value ? changeXl(128, 24) : changeXl(36, 24);
});

const mobileActions = computed(() => {
  switch (inventory.value.status) {
    case EInventoryItemStatus.PREPARING:
      return mobileActionsData.preparing;
    case EInventoryItemStatus.EXCHANGING: {
      return mobileActionsData.exchanging;
    }
    case EInventoryItemStatus.VENDOR_WAITING: {
      return mobileActionsData.vendorWaiting;
    }
    case EInventoryItemStatus.COMPLETED:
    case EInventoryItemStatus.SEND: {
      return mobileActionsData.send;
    }
    default:
      return mobileActionsData.default;
  }
});

const desktopActions = computed(() => {
  const { hours, minutes, seconds } = refinedTime.value;

  switch (inventory.value.status) {
    case EInventoryItemStatus.PREPARING:
      return deskTopActionsData.preparing({ hours, minutes, seconds });
    case EInventoryItemStatus.EXCHANGING: {
      return deskTopActionsData.exchanging({ hours, minutes, seconds });
    }
    case EInventoryItemStatus.VENDOR_WAITING: {
      return deskTopActionsData.vendorWaiting({ hours, minutes, seconds });
    }
    case EInventoryItemStatus.COMPLETED:
    case EInventoryItemStatus.SEND: {
      return deskTopActionsData.send({ hours, minutes, seconds });
    }
    default:
      return deskTopActionsData.default;
  }
});

const inventoryActions = computed<IInventoryItemAction[]>(() => {
  return isMobile.value ? mobileActions.value : desktopActions.value;
});

const isDropItemHovered = ref(false);

const iconSize = computed(() => changeXl(32, 28));

const shouldShowActions = computed(() => {
  return !isItemOld.value && inventoryStore.isCurrentUser;
});

const currentTime = ref();
const { timerData, start } = useTimer(currentTime);

const refinedTime = computed(() => {
  return {
    hours: timerData.value.hours,
    minutes: timerData.value.minutes,
    seconds: timerData.value.seconds,
    rawTime: timerData.value.rawTime,
  };
});

watch(
  () => refinedTime.value.rawTime,
  (newValue) => {
    if (newValue === 0 && inventory.value.status === EInventoryItemStatus.EXCHANGING) {
      inventory.value.status = EInventoryItemStatus.PROGRESS;
    }
  },
);

watch(
  () => inventory.value.timerData,
  (newTimerData) => {
    if (newTimerData) {
      const isUnixTime = newTimerData > inventoryWsStore.unixTimeCap;
      currentTime.value = isUnixTime ? newTimerData * 1000 : Date.now() + newTimerData * 1000;
      start();
    }
  },
);

watch(
  () => inventory.value.trade?.untilTimeout,
  (newTimerData) => {
    const unixTime = Number(newTimerData);
    if (unixTime > 0) {
      currentTime.value = unixTime * 1000;
      start();
    }
  },
);

watch(
  () => inventory.value.status,
  (newTimerData) => {
    if (newTimerData === EInventoryItemStatus.EXCHANGING) {
      ProfileInventoryEvents.changeItemTriggered({
        'Item Name': inventory.value.name,
        'Item Price': Number(inventory.value.price),
        'Item Rarity': inventory.value.rare,
        'Item Type': inventory.value.type ?? 0,
      });
    }
  },
);

const statusClasses = computed<Array<string | Record<string, boolean>>>(() => {
  return [
    'inventory-item__status',
    { 'inventory-item__status--exchanging': inventory.value.status === EInventoryItemStatus.EXCHANGING },
  ];
});

const sourceClasses = computed<Array<string | Record<string, boolean>>>(() => {
  return ['inventory-item__source', { 'inventory-item__source--new': !isItemOld.value }];
});

const buttonsClasses = computed<Array<string | Record<string, boolean>>>(() => {
  return [
    'inventory-item__buttons',
    { 'inventory-item__buttons--progress': inventory.value.status !== EInventoryItemStatus.PROGRESS },
  ];
});

const shouldShowType3Actions = computed(() => {
  return shouldShowActions.value && isMobile.value && isItemType3.value && inventoryStore.quantitySelectedItems;
});

const shouldShowMobileTimer = computed(() => {
  return isMobile.value && inventory.value.status !== EInventoryItemStatus.PROGRESS && refinedTime.value.rawTime > 0;
});
</script>

<template>
  <div class="inventory-item">
    <SharedKitDropItem
      :name="inventory.name"
      :no-overlay="true"
      :image="inventory.img"
      :color="declareColorOfItem(inventory)"
      :currency="user?.finance.currency"
      shining-color="transparent"
      :price="inventory.price"
      shining
      :class="{ 'inventory-item--selected': inventory.isSelected }"
      @mouseenter="isDropItemHovered = true"
      @mouseleave="isDropItemHovered = false"
      @click="inventoryStore.toggleSelectItemState(inventory)"
    >
      <template #top-right>
        <SharedKitTooltip v-if="isItemOld" position="top" class="wrapper-tooltip">
          <template #preview-icon>
            <div :class="statusClasses">
              <component
                :is="itemStatus.component"
                :style="{ color: itemStatus.color }"
                :width="20"
                :height="20"
                @mouseenter.once="ProfileInventoryEvents.hintHovered()"
              />
            </div>
          </template>
          <template #default>
            {{ itemStatus.text }}
          </template>
        </SharedKitTooltip>

        <NuxtLink :to="cameFrom.link" target="_blank">
          <NuxtImg
            v-if="cameFrom.image"
            :src="cameFrom.image"
            loading="lazy"
            provider="localProvider"
            :width="cameFromImageSize"
            :height="cameFromImageSize"
            :class="sourceClasses"
          />
        </NuxtLink>
      </template>
      <template #top-left>
        <SvgoCansellSelected
          v-if="inventory.isSelected && !isMobile"
          class="inventory-item__icon inventory-item__icon--selected"
          :width="iconSize"
          :height="iconSize"
        />
        <SvgoSocialsSteamIcon
          v-if="inventory.type === dropItemTypes['3']"
          class="inventory-item__icon inventory-item__icon--steam"
        />
        <div v-if="shouldShowMobileTimer" class="inventory-item__timer">
          {{ refinedTime.minutes }} : {{ refinedTime.seconds }}
        </div>
      </template>
      <template #buttons>
        <div v-if="shouldShowActions && !isMobile" :class="buttonsClasses">
          <div v-for="action in inventoryActions" :key="action.text" class="inventory-item__button">
            <component
              :is="action.component"
              :form="InventoryItemButtonFormPreset"
              :color="action.colorPreset"
              :text-props="action.textPreset"
              @click="() => action.onClick?.({ id: inventory.id, isType3: isItemType3, inventory: inventory })"
            >
              {{ action.text }}
            </component>
          </div>
        </div>
      </template>
    </SharedKitDropItem>
    <InventoryItemInfo
      v-if="isMobile"
      :status="itemStatus"
      :name="inventory.name"
      :came-from="inventory.from?.from"
      :came-from-info="cameFrom"
      :currency="inventory.currency"
      :price="inventory.price"
      :is-item-old="isItemOld"
      :is-parent-hovered="isDropItemHovered"
    />
    <InventoryItemActions
      v-if="shouldShowType3Actions"
      :actions="type3ActionsData(inventory)"
      :link="cameFrom.link"
      :inventory="inventory"
      :is-type3="isItemType3"
    />
    <InventoryItemActions
      v-else-if="shouldShowActions"
      :actions="inventoryActions"
      :link="cameFrom.link"
      :inventory="inventory"
      :is-type3="isItemType3"
    />
  </div>
</template>

<style scoped lang="scss" src="./InventoryItem.scss" />
