import type {
  IBestDropClickedEvent,
  ICasePromotionClickedEvent,
  IChangeItemClickedEvent,
  IChangeItemTriggeredEvent,
  IChangePopupChangeItemClickedEvent,
  IChangeSellItemClickedEvent,
  IItemSourceClickedEvent,
  IItemsToUpgradeClickedEvent,
  ISellAllItemsClickedEvent,
  ISellItemClickedEvent,
  IWithdrawalItemClickedEvent,
  IWithdrawFewItemsClickedEvent,
} from '../../types/profile/profile-inventory';
import { EProfileInventoryEvents } from '../../types/profile/profile-inventory';

export class ProfileInventoryEvents {
  static sellAllItemsClicked(event: ISellAllItemsClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.SELL_ALL_ITEMS_CLICKED,
      eventProperties: event,
    });
  }

  static sellItemClicked(event: ISellItemClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.SELL_ITEM_CLICKED,
      eventProperties: event,
    });
  }

  static itemsToUpgradeClicked(event: IItemsToUpgradeClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.ITEMS_TO_UPGRADE_CLICKED,
      eventProperties: event,
    });
  }

  static allItemsSwitched() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.ALL_ITEMS_SWITCHED,
    });
  }

  static casePromotionClicked(event: ICasePromotionClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.CASE_PROMOTION_CLICKED,
      eventProperties: event,
    });
  }

  static withdrawalItemClicked(event: IWithdrawalItemClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.WITHDRAWAL_ITEM_CLICKED,
      eventProperties: event,
    });
  }

  static itemSourceClicked(event: IItemSourceClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.ITEM_SOURCE_CLICKED,
      eventProperties: event,
    });
  }

  static acceptTradeClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.ACCEPT_TRADE_CLICKED,
    });
  }

  static changeItemTriggered(event: IChangeItemTriggeredEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.CHANGE_ITEM_TRIGGERED,
      eventProperties: event,
    });
  }

  static changeItemClicked(event: IChangeItemClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.CHANGE_ITEM_CLICKED,
      eventProperties: event,
    });
  }

  static hintHovered() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.HINT_CLICKED,
    });
  }

  static withdrawalFewItemsClicked(event: IWithdrawFewItemsClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.WITHDRAW_FEW_ITEMS_CLICKED,
      eventProperties: event,
    });
  }

  static withdrawalFewItemsCanceled() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.WITHDRAW_FEW_ITEMS_CANCELED,
    });
  }

  static backToInventoryClicked() {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.BACK_TO_INVENTORY_CLICKED,
    });
  }

  static changeSellItemClicked(event: IChangeSellItemClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.CHANGE_SELL_ITEM_CLICKED,
      eventProperties: event,
    });
  }

  static changePopupItemClicked(event: IChangePopupChangeItemClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.CHANGE_ITEM_CLICKED,
      eventProperties: event,
    });
  }

  static bestDropClicked(event: IBestDropClickedEvent) {
    return useAmplitude().sendEvent('DEFAULT', {
      event: EProfileInventoryEvents.BEST_DROP_CLICKED,
      eventProperties: event,
    });
  }
}
