<template>
  <span class="user-avatar-level" :style="{ background: color }">
    {{ displayLevel }}
    <SvgoQuestionIcon v-if="canOpenPopup" class="user-avatar-level__icon" @click="$emit('open-popup')" />
  </span>
</template>

<script setup lang="ts">
import type { IUserAvatarLevelEmits, IUserAvatarLevelProps } from './UserAvatarLevel.types';

const props = defineProps<IUserAvatarLevelProps>();
defineEmits<IUserAvatarLevelEmits>();

const displayLevel = computed(() => Math.max(0, props.level));
</script>

<style scoped lang="scss" src="./UserAvatarLevel.scss"></style>
